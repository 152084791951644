import { inject, Injectable } from '@angular/core';
import { ProductCode } from '@mkp/shared/util-product';
import { formatISO9075 } from 'date-fns';
import { exhaustMap, map, Observable, of, switchMap } from 'rxjs';
import { BaseHttpResource } from '../base-http.resource';
import { CreditRedemptionDto } from '../credit-redemption/credit-redemption.dto';
import { CreditRedemptionState } from '../credit-redemption/credit-redemption.model';
import { CreditRedemptionResource } from '../credit-redemption/credit-redemption.resource';
import { CoreListEnvelop } from '../list-envelop/core-list-envelop.model';
import { UUID } from '../uuid.model';
import { CreditDto, CreditState } from './credit.dto';
import { isFreeProductCode } from './credit.util';

type PreviousCreditRedemptionData = Pick<CreditRedemptionDto, 'id' | '_version' | '_state'> & {
  productType: ProductCode;
};

@Injectable({ providedIn: 'root' })
export class CreditResource extends BaseHttpResource<CreditDto, CoreListEnvelop<CreditDto>> {
  private readonly creditRedemptionResource = inject(CreditRedemptionResource);

  constructor() {
    super('credit');
  }

  useCredit(
    accountId: string | UUID,
    vacancyId: string | UUID,
    product: ProductCode,
    { id, _version, _state, productType }: PreviousCreditRedemptionData
  ): Observable<unknown> {
    const start = formatISO9075(new Date());
    const filter = `account.id==${accountId};product.type==${product};_availableCredits>0;validTo>${start}`;
    const sort = `createdAt=asc`; // FIFO

    let prior = of({});

    if (id && _version && _state === CreditRedemptionState.Active) {
      if (isFreeProductCode(productType)) {
        prior = this.creditRedemptionResource.update(id, { id, _version, stoppedAt: 'now' });
      } else {
        // we probably don't need this because upgrading a paid product is now handled via customer service (opens an intercom chat)
        prior = this.update(id, { id, _version, state: CreditState.Refund });
      }
    }

    return prior.pipe(
      switchMap(() =>
        this.getWithQuery({ limit: 1, filter, sort }).pipe(
          map((response) => response._embedded.results[0]),
          exhaustMap(({ id }) =>
            this.http.post(`${this.url}/${id}/redeem`, { vacancyId, start: 'now' })
          )
        )
      )
    );
  }
}
